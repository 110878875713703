// Generated by Framer (c2f6d5d)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFonts, useActiveVariantCallback, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";
import Tab from "https://framerusercontent.com/modules/M42MM5QoUdAgIsIpdSJx/tzXSeR7bB4rZke2ocz0Z/VAMUDsO6f.js";
const TabFonts = getFonts(Tab);

const cycleOrder = ["VV5Jlcfiu", "Uid__I1qs", "DdwxRx3OH"];

const variantClassNames = {DdwxRx3OH: "framer-v-13tr7a6", Uid__I1qs: "framer-v-zddk8l", VV5Jlcfiu: "framer-v-fd8n3c"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {"Tab 1": "VV5Jlcfiu", "Tab 2": "Uid__I1qs", "Tab 3": "DdwxRx3OH"};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; tab1?: string; tab2?: string; tab3?: string }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "VV5Jlcfiu", tab1: MSGp_zYf0 = "Tab 1", tab2: f72tcvvi2 = "Tab 2", tab3: GuQmBQnFI = "Tab 3", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "VV5Jlcfiu", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const tap1gsqetm = activeVariantCallback(async (...args) => {
setVariant("VV5Jlcfiu")
})

const tap1ydaak9 = activeVariantCallback(async (...args) => {
setVariant("Uid__I1qs")
})

const tap1bbw2nb = activeVariantCallback(async (...args) => {
setVariant("DdwxRx3OH")
})

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-GlXk5", classNames)} style={{display: "contents"}}>
<motion.div {...restProps} className={cx("framer-fd8n3c", className)} data-framer-name={"Tab 1"} layoutDependency={layoutDependency} layoutId={"VV5Jlcfiu"} ref={ref} style={{...style}} transition={transition} {...addPropertyOverrides({DdwxRx3OH: {"data-framer-name": "Tab 3"}, Uid__I1qs: {"data-framer-name": "Tab 2"}}, baseVariant, gestureVariant)}><motion.div className={"framer-tczza8-container"} layoutDependency={layoutDependency} layoutId={"xPyClBf2G-container"} transition={transition}><Tab height={"100%"} id={"xPyClBf2G"} layoutId={"xPyClBf2G"} title={MSGp_zYf0} variant={"Ho9BqSUe0"} width={"100%"} {...addPropertyOverrides({DdwxRx3OH: {tap: tap1gsqetm, variant: "ym0P2kopY"}, Uid__I1qs: {tap: tap1gsqetm, variant: "ym0P2kopY"}}, baseVariant, gestureVariant)}/></motion.div><motion.div className={"framer-w371h7-container"} layoutDependency={layoutDependency} layoutId={"IpRIPgquC-container"} transition={transition}><Tab height={"100%"} id={"IpRIPgquC"} layoutId={"IpRIPgquC"} tap={tap1ydaak9} title={f72tcvvi2} variant={"ym0P2kopY"} width={"100%"} {...addPropertyOverrides({Uid__I1qs: {variant: "Ho9BqSUe0"}}, baseVariant, gestureVariant)}/></motion.div><motion.div className={"framer-n1nw0k-container"} layoutDependency={layoutDependency} layoutId={"U8x0RgQPA-container"} transition={transition}><Tab height={"100%"} id={"U8x0RgQPA"} layoutId={"U8x0RgQPA"} tap={tap1bbw2nb} title={GuQmBQnFI} variant={"ym0P2kopY"} width={"100%"} {...addPropertyOverrides({DdwxRx3OH: {variant: "Ho9BqSUe0"}}, baseVariant, gestureVariant)}/></motion.div></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-GlXk5 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-GlXk5 .framer-12mhvae { display: block; }", ".framer-GlXk5 .framer-fd8n3c { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 12px; height: min-content; justify-content: flex-start; overflow: visible; padding: 0px 0px 0px 0px; position: relative; width: min-content; }", ".framer-GlXk5 .framer-tczza8-container, .framer-GlXk5 .framer-w371h7-container, .framer-GlXk5 .framer-n1nw0k-container { flex: none; height: auto; position: relative; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-GlXk5 .framer-fd8n3c { gap: 0px; } .framer-GlXk5 .framer-fd8n3c > * { margin: 0px; margin-left: calc(12px / 2); margin-right: calc(12px / 2); } .framer-GlXk5 .framer-fd8n3c > :first-child { margin-left: 0px; } .framer-GlXk5 .framer-fd8n3c > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 49
 * @framerIntrinsicWidth 275.5
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"Uid__I1qs":{"layout":["auto","auto"]},"DdwxRx3OH":{"layout":["auto","auto"]}}}
 * @framerVariables {"MSGp_zYf0":"tab1","f72tcvvi2":"tab2","GuQmBQnFI":"tab3"}
 */
const FramerYMbXlnlCf: React.ComponentType<Props> = withCSS(Component, css, "framer-GlXk5") as typeof Component;
export default FramerYMbXlnlCf;

FramerYMbXlnlCf.displayName = "Elements/Tabs Copy";

FramerYMbXlnlCf.defaultProps = {height: 49, width: 275.5};

addPropertyControls(FramerYMbXlnlCf, {variant: {options: ["VV5Jlcfiu", "Uid__I1qs", "DdwxRx3OH"], optionTitles: ["Tab 1", "Tab 2", "Tab 3"], title: "Variant", type: ControlType.Enum}, MSGp_zYf0: {defaultValue: "Tab 1", displayTextArea: false, title: "Tab 1", type: ControlType.String}, f72tcvvi2: {defaultValue: "Tab 2", displayTextArea: false, title: "Tab 2", type: ControlType.String}, GuQmBQnFI: {defaultValue: "Tab 3", displayTextArea: false, title: "Tab 3", type: ControlType.String}} as any)

addFonts(FramerYMbXlnlCf, [...TabFonts])